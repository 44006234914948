export const USER_ROLES = [
    { id: 'admin', name: 'Administrator' },
    { id: 'parent', name: 'Parent' },
    { id: 'teacher', name: 'Teacher' },
    { id: 'customer_experience', name: 'Customer experience' },
    { id: 'student', name: 'Student' },
    { id: 'editor', name: 'Editor' },
];

export const USER_SOURCES = [
    { id: 'stripe', name: 'Stripe' },
    { id: 'manual', name: 'Manual' },
    { id: 'web', name: 'Web' },
    { id: 'web-join', name: 'Web - Default Join Flow' },
    { id: 'web-redeem', name: 'Web - Redeem Flow' },
    { id: 'web-start', name: 'Web - Start Flow' },
    { id: 'web-free-teacher', name: 'Web - Free Teacher' },
    { id: 'web-read-along', name: 'Web - Read Along' },
    { id: 'ios', name: 'iOS' },
    { id: 'mackinvia.com', name: 'MackinVia' },
    { id: 'clever.com', name: 'Clever Library' },
    { id: 'clever.com/secure-sync', name: 'Clever Sync' },
    { id: 'read-along', name: 'Read-along' },
    { id: 'partner-register', name: 'Partner register' },
    { id: 'android', name: 'Android' },
]

export const SUBSCRIPTION_STORES = [
    { id: 'app_store', name: 'App store' },
    { id: 'play_store', name: 'Play store' },
    { id: 'stripe', name: 'Stripe' },
]
