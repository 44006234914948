import React from "react";
import {
  Edit,
  SimpleForm,
  TextField,
  ReferenceField,
  Toolbar,
  DeleteWithConfirmButton,
  EditButton,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { validateSuggestions } from "../../util";

const useStyles = makeStyles({
  toolbar: { display: "flex", justifyContent: "space-between" },
});

const CustomToolbar = (props) => (
  <Toolbar {...props} classes={useStyles()}>
    <DeleteWithConfirmButton
      confirmTitle={`Delete filter "${props.record.name}"`}
      confirmContent="Are you sure you want to delete this filter? This action is will update the tag."
    />
  </Toolbar>
);

const FilterTitle = ({ record }) => (
  <span>Filter {record ? `"${record.name}"` : "edit"}</span>
);

const TagEditButton = ({ record }) => (
  <EditButton basePath="/tags" label="Edit tag" record={record} />
);

export const FilterEdit = (props) => (
  <Edit title={<FilterTitle />} undoable={false} {...props}>
    <SimpleForm toolbar={<CustomToolbar />} warnWhenUnsavedChanges>
      <TextField source="name" fullWidth />
      <TextField source="code" fullWidth />
      <ReferenceField
        label="Parent"
        reference="tags"
        source="parent"
        perPage={10000}
        validate={validateSuggestions}
        allowEmpty
        emptyText="-- NO PARENT --"
        fullWidth
      >
        <TextField source="name" />
      </ReferenceField>
      <TagEditButton {...props} />
    </SimpleForm>
  </Edit>
);
