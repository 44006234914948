import React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  Pagination,
  Filter,
  TextInput,
  BooleanInput,
} from "react-admin";

const BooksPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 20, 50, 100]} {...props} />
);

const BookFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn resettable />
    <TextInput label="Title" source="title" resettable />
    <TextInput label="Author" source="author" resettable />
    <BooleanInput label="Is live" source="is_live" />
  </Filter>
);

export const BookList = (props) => (
  <List
    {...props}
    exporter={false}
    perPage={20}
    bulkActionButtons={false}
    pagination={<BooksPagination />}
    filters={<BookFilter />}
  >
    <Datagrid rowClick="edit">
      <TextField source="title" />
      <TextField source="author" />
      <BooleanField source="is_live" sortable={false} />
      <BooleanField source="is_public" sortable={false} />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
);
