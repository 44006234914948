import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Pagination,
  Filter,
  TextInput,
} from "react-admin";

const UsersPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 20, 50, 100]} {...props} />
);

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn resettable />
    <TextInput label="First Name" source="first_name" resettable />
    <TextInput label="Last Name" source="last_name" resettable />
    <TextInput label="Email" source="email" resettable />
    <TextInput label="Role" source="role" resettable />
  </Filter>
);

export const UserList = (props) => (
  <List
    {...props}
    exporter={false}
    perPage={20}
    bulkActionButtons={false}
    pagination={<UsersPagination />}
    filters={<UserFilter />}
  >
    <Datagrid rowClick="edit">
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextField source="email" />
      <TextField source="role" />
    </Datagrid>
  </List>
);
