import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Pagination,
  BooleanField,
  Filter,
  TextInput,
  ImageField, BooleanInput,
} from "react-admin";

const InterestPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 20, 50, 100]} {...props} />
);

const InterestFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn resettable />
    <TextInput label="Name" source="name" resettable />
    <TextInput label="Code" source="code" resettable />
    <BooleanInput label="Is visible" source="is_visible" />
  </Filter>
);

export const InterestList = (props) => {
  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      perPage={20}
      pagination={<InterestPagination />}
      filters={<InterestFilter />}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="code" />
        <ImageField
          source="image_original_img"
          label="Image"
          className="interest-image"
          sortable={false}
        />
        <BooleanField source="is_visible" sortable={false} />
      </Datagrid>
    </List>
  );
};
