import { adminApiUrl } from "../config";
import { httpClient } from "../util";

export const getAllTags = () => {
  return httpClient(`${adminApiUrl}/tags?pageSize=10000`, {
    method: "GET",
  }).then(({ json }) => ({ data: json }));
};

export const addTagToBook = (payload) => {
  return httpClient(`${adminApiUrl}/tags/tag-book`, {
    method: "POST",
    body: JSON.stringify(payload),
  }).then(({ json }) => ({ data: json }));
};

export const removeTagFromBook = (tagId, bookId) => {
  return httpClient(
    `${adminApiUrl}/tags/${tagId}/books/${bookId}?pageSize=100`,
    {
      method: "DELETE",
    }
  ).then(({ json }) => ({ data: json }));
};

export const uploadTagImage = ({ id, device, imageFile }) => {
  let formData = new FormData();
  formData.append("uploads", imageFile);
  return httpClient(
    `${adminApiUrl}/tags/${id}/uploads/${device}`,
    {
      method: "POST",
      body: formData,
    },
    true
  ).then(({ json }) => ({ data: json }));
};
