import React from "react";
import { Create, SimpleForm, TextInput, BooleanInput } from "react-admin";
import { DateInput } from "react-admin-date-inputs2";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  validateTitle,
  validateFieldLength,
  validateAgeLevelField,
  validateShortFieldLength,
} from "../../util";

export const BookCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list" warnWhenUnsavedChanges>
      <TextInput source="title" fullWidth validate={validateTitle} />
      <TextInput
        source="author"
        fullWidth
        validate={validateShortFieldLength}
      />
      <TextInput
        source="description_short"
        label="Short description"
        multiline
        fullWidth
      />
      <TextInput
        source="description_long"
        label="Description"
        multiline
        fullWidth
      />
      <TextInput
        source="age_level"
        fullWidth
        validate={validateAgeLevelField}
      />
      <TextInput
        source="illustrators"
        fullWidth
        validate={validateFieldLength}
      />
      <BooleanInput label="Is Public" source="is_public" fullWidth />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateInput
          source="copyright_year"
          label="Copyright Year"
          options={{ format: "MM/dd/yyyy" }}
        />
      </MuiPickersUtilsProvider>
    </SimpleForm>
  </Create>
);
