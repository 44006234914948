import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import { validateName } from "../../util";

export const AvatarCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list" warnWhenUnsavedChanges>
      <TextInput source="name" validate={validateName} />
      <ReferenceInput
        source="avatar_collection_id"
        reference="avatar-collections"
      >
        <SelectInput optionText="name" resettable />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
