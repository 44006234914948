import React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";

export const AvatarCollectionCreate = (props) => (
  <Create title="Create Avatar Collection" {...props}>
    <SimpleForm redirect="list" warnWhenUnsavedChanges>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);
