import React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  Pagination,
  Filter,
  TextInput,
  BooleanInput,
  Button,
  useUpdate,
  useNotify,
} from "react-admin";

const PopularSearchPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 20, 50, 100]} {...props} />
);

const PopularSearchFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn resettable />
    <TextInput label="Term" source="term" resettable />
    <BooleanInput label="Visible" source="visibility" />
  </Filter>
);

const VisibilityFieldInput = ({ record }) => {
  const notify = useNotify();
  const diff = { visibility: !record.visibility };

  const [update, { loading, error }] = useUpdate(
    "popularsearch",
    record.id,
    diff,
    record
  );

  if (error) {
    notify(
      "An error ocurred trying to update the visibility, please try again",
      "warning"
    );
  }

  return (
    <Button
      disabled={loading}
      onClick={update}
      label="Toggle Visibility"
    ></Button>
  );
};

export const PopularSearchList = (props) => (
  <List
    {...props}
    exporter={false}
    perPage={20}
    bulkActionButtons={false}
    pagination={<PopularSearchPagination />}
    filters={<PopularSearchFilter />}
    title="Popular Searches"
    sort={{ field: 'counter', order: 'DESC' }}
  >
    <Datagrid rowClick="edit">
      <TextField source="term" label="Searched string" sortable/>
      <TextField source="counter" label="Times searched" sortable/>
      <BooleanField source="visibility" label="Is live" sortable/>
      <VisibilityFieldInput />
    </Datagrid>
  </List>
);
