import { apiUrl } from "../config";
import { httpClient } from "../util";
import { stringify } from "query-string";

export const patchForMuxData = (bookId) => {
  return httpClient(`${apiUrl}/books/video-upload-url`, {
    method: "PATCH",
    body: JSON.stringify({ book_id: bookId }),
  }).then(({ json }) => ({ data: json }));
};

export const updateVideoStatus = (payload) => {
  return httpClient(`${apiUrl}/books/update-video-status`, {
    method: "PATCH",
    body: JSON.stringify(payload),
  }).then(({ json }) => ({ data: json }));
};

export const uploadImage = ({ bookId, imageScope, imageFile }) => {
  let formData = new FormData();
  formData.append("uploads", imageFile);
  return httpClient(
    `${apiUrl}/books/${bookId}/uploads/${imageScope}/original`,
    {
      method: "PATCH",
      body: formData,
    },
    true
  ).then(({ json }) => ({ data: json }));
};

export const getImageByScope = ({ bookId, imageScope }) => {
  return httpClient(`${apiUrl}/books/${bookId}/image/${imageScope}`, {
    method: "GET",
  }).then(({ json }) => ({ data: json }));
};

export const deleteImageByScope = ({ bookId, imageScope }) => {
  return httpClient(`${apiUrl}/books/${bookId}/image/${imageScope}/original`, {
    method: "DELETE",
  }).then(({ json }) => ({ data: json }));
};

export const getBookVideo = (bookId) => {
  return httpClient(`${apiUrl}/books/${bookId}/video`, {
    method: "GET",
  }).then(({ json }) => ({ data: json }));
};

export const deleteBookVideo = (bookId) => {
  return httpClient(`${apiUrl}/books/${bookId}/video`, {
    method: "DELETE",
  }).then(({ json }) => ({ data: json }));
};

export const getBookTags = (bookId) => {
  return httpClient(`${apiUrl}/books/${bookId}/tags`, {
    method: "GET",
  }).then(({ json }) => ({ data: json }));
};

export const getBookActivities = (bookId, payload) => {
  return httpClient(`${apiUrl}/books/${bookId}/activities?${stringify(payload)}`, {
    method: "GET",
  }).then(({ json }) => ({ data: json }));
};
