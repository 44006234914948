import React, { useState } from "react";
import {
  Edit,
  SimpleForm,
  TextField,
  ReferenceField,
  Toolbar,
  DeleteWithConfirmButton,
  useRefresh,
  FileInput,
  LinearProgress,
  BooleanInput,
  SaveButton,
  EditButton,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import { validateSuggestions } from "../../util";
import { uploadTagImage } from "../../services/tag";

const useStyles = makeStyles({
  toolbar: { display: "flex", justifyContent: "space-between" },
});

const TagEditButton = ({ record }) => (
  <EditButton basePath="/tags" label="Edit tag" record={record} />
);

const CustomToolbar = (props) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
    <DeleteWithConfirmButton
      confirmTitle={`Delete interest "${props.record.name}"`}
      confirmContent="Are you sure you want to delete this interest? This action is will update the tag."
    />
  </Toolbar>
);

const InterestTitle = ({ record }) => (
  <span>Interest {record ? `"${record.name}"` : "edit"}</span>
);

const ImageUploader = ({ record }) => {
  const refresh = useRefresh();
  const [uploading, setUploading] = useState(false);

  const uploadThisImage = (file) => {
    if (file) {
      setUploading(true);

      uploadTagImage({
        id: record.id,
        device: "original",
        imageFile: file,
      }).finally(refresh);
    }
  };

  return (
    <>
      {!uploading && (
        <>
          <FileInput
            source="image_original_img"
            label="Upload/Update Image"
            accept="image/*"
            onChange={uploadThisImage}
          >
            <></>
          </FileInput>
          {record.image_original_img && !uploading && (
            <>
              <FormLabel
                focused={false}
                style={{
                  margin: "16px 0px",
                  display: "block",
                  fontSize: "0.75rem",
                }}
              >
                Current Image
              </FormLabel>
              <img
                src={record.image_original_img}
                alt=""
                className="interest-image-img"
              />
            </>
          )}
        </>
      )}
      {uploading && (
        <>
          <LinearProgress />
          <span style={{ display: "block", marginTop: 8 }}>
            Uploading Image..
          </span>
        </>
      )}
    </>
  );
};

export const InterestEdit = (props) => (
  <Edit title={<InterestTitle />} undoable={false} {...props}>
    <SimpleForm toolbar={<CustomToolbar />} warnWhenUnsavedChanges>
      <TextField source="name" fullWidth />
      <TextField source="code" fullWidth />
      <ReferenceField
        label="Parent"
        reference="tags"
        source="parent"
        perPage={10000}
        validate={validateSuggestions}
        allowEmpty
        emptyText="-- NO PARENT --"
        fullWidth
      >
        <TextField source="name" />
      </ReferenceField>
      <BooleanInput label="Visible" source="is_visible" fullWidth />
      <ImageUploader {...props} />
      <TagEditButton {...props} />
    </SimpleForm>
  </Edit>
);
