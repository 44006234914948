import React, { useState } from "react";
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  ReferenceInput,
  SelectInput,
  Toolbar,
  SaveButton,
  DeleteWithConfirmButton,
  useRefresh,
  FileInput,
  LinearProgress,
  BooleanInput, Confirm,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import {deleteAvatarImage, uploadAvatarImage} from "../../services/avatar";
import DeleteIcon from "@material-ui/icons/Delete";
import {red} from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  toolbar: { display: "flex", justifyContent: "space-between" },
});

const CustomToolbar = (props) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
    <DeleteWithConfirmButton
      confirmTitle={`Delete avatar "${props.record.name}"`}
      confirmContent="Are you sure you want to delete this Avatar? This action is permanent."
    />
  </Toolbar>
);

const ImageUploader = ({ record }) => {
  const refresh = useRefresh();
  const [uploading, setUploading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const uploadThisImage = (file) => {
    if (file) {
      setUploading(true);

      uploadAvatarImage({
        id: record.id,
        device: "original",
        imageFile: file,
      }).finally(refresh);
    }
  };

  const deleteThisImage = () => deleteAvatarImage({ id: record.id }).finally(refresh);

  return (
    <>
      {!uploading && (
        <>
          <FileInput
            source="image"
            label="Upload/Update Image"
            accept="image/*"
            onChange={uploadThisImage}
          >
            <></>
          </FileInput>
          {record.image_original_img && !uploading && (
            <>
              <FormLabel
                focused={false}
                style={{
                  margin: "16px 0px",
                  display: "block",
                  fontSize: "0.75rem",
                }}
              >
                Current Image
              </FormLabel>
              <img
                src={record.image_original_img}
                alt=""
                className={"avatar-image-img"}
              />
              <br/>
              <Button
                  className="btn-delete"
                  variant="contained"
                  color="primary"
                  startIcon={<DeleteIcon />}
                  size="small"
                  style={{
                    backgroundColor: red[500],
                    width: 165,
                  }}
                  onClick={() => setConfirmOpen(true)}
              >
                DELETE IMAGE
              </Button>
            </>
          )}
          <Confirm
              isOpen={confirmOpen}
              title="Delete image"
              content="Are you sure you want to delete this image?"
              onConfirm={deleteThisImage}
              onClose={() => setConfirmOpen(false)}
          />
        </>
      )}
      {uploading && (
        <>
          <LinearProgress />
          <span style={{ display: "block", marginTop: 8 }}>
            Uploading Image..
          </span>
        </>
      )}
    </>
  );
};

export const AvatarEdit = (props) => {
  const refresh = useRefresh();

  return (
    <Edit undoable={false} {...props}>
      <TabbedForm
        redirect="edit"
        toolbar={<CustomToolbar />}
        warnWhenUnsavedChanges
        submitOnEnter={false}
      >
        <FormTab label="Properties">
          <TextInput source="name" />
          <BooleanInput source="visibility" />
          <ReferenceInput
            source="avatar_collection_id"
            reference="avatar-collections"
          >
            <SelectInput optionText="name" resettable />
          </ReferenceInput>
        </FormTab>
        <FormTab label="Image" onClick={refresh}>
          <ImageUploader {...props} />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
