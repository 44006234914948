import React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";
import { validateTitle } from "../../util";

export const CustomFilterCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list" warnWhenUnsavedChanges>
      <TextInput source="name" fullWidth validate={validateTitle} />
      <TextInput source="code" fullWidth validate={validateTitle} />
    </SimpleForm>
  </Create>
);
