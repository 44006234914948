import React, { useState } from "react";
import {
  Edit,
  TextInput,
  FileInput,
  Confirm,
  useRefresh,
  Toolbar,
  SaveButton,
  DeleteWithConfirmButton,
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
  TabbedForm,
  FormTab,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { validateSuggestions, validateName, validateURI } from "../../util";
import {
  uploadActivityImage,
  deleteActivityImage,
} from "../../services/activitiy";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import LinearProgress from "@material-ui/core/LinearProgress";
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles({
  toolbar: { display: "flex", justifyContent: "space-between" },
});

const CustomToolbar = (props) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
    <DeleteWithConfirmButton
      confirmTitle={`Delete activity "${props.record.name}"`}
      confirmContent="Are you sure you want to delete this activity? This action is permanent."
    />
  </Toolbar>
);

const ActivityTitle = ({ record }) => (
  <span>Activity {record ? `"${record.name}"` : "edit"}</span>
);

const ImageUploader = ({ record }) => {
  const refresh = useRefresh();
  const [uploading, setUploading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const uploadThisImage = (file) => {
    if (file) {
      setUploading(true);

      uploadActivityImage({
        id: record.id,
        device: "original",
        imageFile: file,
      }).finally(refresh);
    }
  };

  const deleteThisImage = () =>
    deleteActivityImage({ id: record.id }).finally(refresh);

  return (
    <>
      {!uploading && (
        <>
          <FileInput
            source="image"
            label="Upload/Update Image"
            accept="image/*"
            onChange={uploadThisImage}
          >
            <></>
          </FileInput>
          {record.image_original_img && !uploading && (
            <>
              <FormLabel
                focused={false}
                style={{
                  margin: "16px 0px",
                  display: "block",
                  fontSize: "0.75rem",
                }}
              >
                Current Image
              </FormLabel>
              <img
                src={record.image_original_img}
                alt=""
                className={"avatar-image-img"}
              />
              <br />
              <Button
                className="btn-delete"
                variant="contained"
                color="primary"
                startIcon={<DeleteIcon />}
                size="small"
                style={{
                  backgroundColor: red[500],
                  width: 165,
                }}
                onClick={() => setConfirmOpen(true)}
              >
                DELETE IMAGE
              </Button>
            </>
          )}
          <Confirm
            isOpen={confirmOpen}
            title="Delete image"
            content="Are you sure you want to delete this image?"
            onConfirm={deleteThisImage}
            onClose={() => setConfirmOpen(false)}
          />
        </>
      )}
      {uploading && (
        <>
          <LinearProgress />
          <span style={{ display: "block", marginTop: 8 }}>
            Uploading Image..
          </span>
        </>
      )}
    </>
  );
};

export const ActivityEdit = (props) => {
  const refresh = useRefresh();
  return (
    <Edit title={<ActivityTitle />} undoable={false} {...props}>
      <TabbedForm
        redirect="edit"
        toolbar={<CustomToolbar />}
        warnWhenUnsavedChanges
      >
        <FormTab label="Activity">
          <TextInput source="name" fullWidth validate={validateName} />
          <TextInput source="description" fullWidth />
          <TextInput source="resource_uri" fullWidth validate={validateURI} />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              !formData.is_custom && (
                <ReferenceInput
                  label="Book"
                  reference="books"
                  source="book_id"
                  perPage={10000}
                  validate={validateSuggestions}
                  emptyText="-- NO BOOK --"
                  fullWidth
                >
                  <AutocompleteInput optionText="title" />
                </ReferenceInput>
              )
            }
          </FormDataConsumer>
        </FormTab>
        <FormTab label="Images" onClick={refresh}>
          <ImageUploader {...props} />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
