import React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  Pagination,
  ReferenceArrayField,
  SingleFieldList,
  Filter,
  TextInput,
  BooleanInput,
} from "react-admin";
import Chip from "@material-ui/core/Chip";

const AvatarCollectionPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 20, 50, 100]} {...props} />
);

const AvatarCollectionFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn resettable />
    <TextInput label="Name" source="name" resettable />
    <BooleanInput label="Visible" source="visibility" />
  </Filter>
);

const ReferenceFieldComp = ({ record }) => {
  if (record) {
    return (
      <span
        className="avatar-image-sm"
        style={{ margin: 4, display: "inline-flex" }}
      >
        {record.image_original_img ? (
          <img src={record.image_original_img} alt={record.name} />
        ) : (
          <Chip label={record.name} style={{ cursor: "pointer" }} />
        )}
      </span>
    );
  }

  return null;
};

export const AvatarCollectionList = (props) => (
  <List
    {...props}
    exporter={false}
    perPage={20}
    bulkActionButtons={false}
    pagination={<AvatarCollectionPagination />}
    filters={<AvatarCollectionFilter />}
    title="Avatar Collections"
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <BooleanField source="visibility" sortable={false} />
      <ReferenceArrayField label="Avatars" reference="avatars" source="avatars" sortable={false}>
        <SingleFieldList>
          <ReferenceFieldComp />
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
  </List>
);
