import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import { validateTitle } from "../../util";

export const FilterCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list" warnWhenUnsavedChanges>
      <TextInput source="name" fullWidth validate={validateTitle} />
      <TextInput source="code" fullWidth validate={validateTitle} />
      <ReferenceInput
        label="Parent"
        reference="tags"
        source="parent"
        perPage={10000}
        fullWidth
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
