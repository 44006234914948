import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  Toolbar,
  SaveButton,
  DeleteWithConfirmButton,
  BooleanInput,
  FormDataConsumer,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { validateTitle, validateSuggestions } from "../../util";

const useStyles = makeStyles({
  toolbar: { display: "flex", justifyContent: "space-between" },
});

const CustomToolbar = (props) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
    <DeleteWithConfirmButton
      confirmTitle={`Delete tag "${props.record.name}"`}
      confirmContent="Are you sure you want to delete this tag? This action is permanent."
    />
  </Toolbar>
);

const TagTitle = ({ record }) => (
  <span>Tag {record ? `"${record.name}"` : "edit"}</span>
);

export const TagEdit = (props) => (
  <Edit title={<TagTitle />} undoable={false} {...props}>
    <SimpleForm toolbar={<CustomToolbar />} warnWhenUnsavedChanges>
      <TextInput source="name" fullWidth validate={validateTitle} />
      <TextInput source="code" fullWidth validate={validateTitle} />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          !formData.is_custom && (
            <ReferenceInput
              label="Parent"
              reference="tags"
              source="parent"
              perPage={10000}
              validate={validateSuggestions}
              allowEmpty
              emptyText="-- NO PARENT --"
              fullWidth
            >
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
          )
        }
      </FormDataConsumer>
      <BooleanInput source="is_interest" />
      <BooleanInput source="is_filter" />
      <BooleanInput source="is_custom" />
    </SimpleForm>
  </Edit>
);
