import { adminApiUrl } from "../config";
import { httpClient } from "../util";

export const getAllChannels = () => {
  return httpClient(`${adminApiUrl}/channels/?pageSize=10000&page=0&sort=order`, {
    method: "GET",
  }).then(({ json }) => ({ data: json }));
};

export const reorderChannels = (channelsIds) => {
  return httpClient(`${adminApiUrl}/channels/sort`, {
    method: "PATCH",
    body: JSON.stringify(channelsIds),
  }).then(({ json }) => ({ data: json }));
};

export const reorderBooks = (channelId, bookIds) => {
  const data = {
    channel: {
      books_ids: bookIds,
    },
  };

  return httpClient(`${adminApiUrl}/channels/${channelId}/books/sort`, {
    method: "PATCH",
    body: JSON.stringify(data),
  }).then(({ json }) => ({ data: json }));
};

export const uploadImage = ({ channelId, imageScope, imageFile }) => {
  let formData = new FormData();
  formData.append("uploads", imageFile);
  return httpClient(
    `${adminApiUrl}/channels/${channelId}/uploads/${imageScope}/original`,
    {
      method: "PATCH",
      body: formData,
    },
    true
  ).then(({ json }) => ({ data: json }));
};

export const getImageByScope = ({ bookId, imageScope }) => {
  return httpClient(`${adminApiUrl}/channels/${bookId}/image/${imageScope}`, {
    method: "GET",
  }).then(({ json }) => ({ data: json }));
};

export const deleteImageByScope = ({ channelId, imageScope }) => {
  return httpClient(
    `${adminApiUrl}/channels/${channelId}/image/${imageScope}/original`,
    {
      method: "DELETE",
    }
  ).then(({ json }) => ({ data: json }));
};
