import { adminApiUrl } from "../config";
import { httpClient } from "../util";

export const removeActivity = (activityId, bookId) => {
  return httpClient(`${adminApiUrl}/activities/${activityId}`, {
    method: "DELETE",
  }).then(({ json }) => ({ data: json }));
};

export const uploadActivityImage = ({ id, device, imageFile }) => {
  let formData = new FormData();
  formData.append("uploads", imageFile);
  return httpClient(
    `${adminApiUrl}/activities/${id}/uploads/${device}`,
    {
      method: "PATCH",
      body: formData,
    },
    true
  ).then(({ json }) => ({ data: json }));
};

export const deleteActivityImage = ({ id, device = "original" }) => {
  return httpClient(`${adminApiUrl}/activities/${id}/image/${device}`, {
    method: "DELETE",
  }).then(({ json }) => ({ data: json }));
};
