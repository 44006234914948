import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import { validateName, validateURI } from "../../util";

export const ActivityCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list" warnWhenUnsavedChanges>
      <TextInput source="name" fullWidth validate={validateName} />
      <TextInput source="description" fullWidth />
      <TextInput source="resource_uri" fullWidth validate={validateURI} />
      <ReferenceInput
        label="Book"
        reference="books"
        source="book_id"
        perPage={10000}
        fullWidth
      >
        <AutocompleteInput optionText="title" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
