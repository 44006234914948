import {
  fetchUtils,
  required,
  minLength,
  maxLength,
  email,
  regex,
} from "react-admin";

export const validateTitle = [required(), minLength(3), maxLength(255)];
export const validateName = [required(), minLength(8), maxLength(255)];
export const validateFieldLength = [maxLength(255)];
export const validateEmail = [email()];
export const validateShortFieldLength = [maxLength(100)];
export const validateSlug = [
  required(),
  minLength(3),
  maxLength(255),
  regex(
    /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
    "Slug only supports lowercase or numbers in '-'"
  ),
];
export const validateAgeLevelField = regex(
  /^[0-9]+-[0-9]+$/,
  "Must match a range of ages, i.e: 9-12"
);
export const validatePassword = regex(
  /^(?=.*[@$#!%*?&])[A-Za-z\d@$#!%*?&]{8,}$/,
  "password must be at least 8 characters and include at least 1 special character"
);
export const validatePasswordMatch = (value, { password }) => {
  if (value && !password) {
    return "You need to add a password first";
  }

  if (value !== password) {
    return "Passwords don't match";
  }
};

export const validateSuggestions = (value, { id }) => {
  if (value === id) {
    return "Tags cannot be parents of themselves";
  }
};

export const httpClient = (url, options = {}, hasFile) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }

  if (hasFile) {
    options.headers = new Headers({ contentType: "multipart/form-data" });
  }

  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options).catch((error) => {
    let message = "";

    if (error.body.error.details) {
      message = error.body.error.details.toString();

      if (Array.isArray(error.body.error.details)) {
        message = error.body.error.details[0].message;
      }

      if (error.body.error.details.message) {
        message = error.body.error.details.message;
      }
    } else {
      message = error.message;
    }

    return Promise.reject(message);
  });
};

export const validateURI = regex(/^(http|https):\/\/[^ "]+$/, "Invalid URL");

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
