import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  Pagination,
  // Filter,
  // TextInput,
  // ReferenceInput,
  // SelectInput,
} from "react-admin";

const CustomFilterPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 20, 50, 100]} {...props} />
);

const MyReferenceField = (props) => {
  return (
    <ReferenceField {...props}>
      <TextField source="name" />
    </ReferenceField>
  );
};

// const CustomFilterFilter = (props) => (
//   <Filter {...props}>
//     <TextInput label="Search" source="q" alwaysOn resettable />
//     <TextInput label="Name" source="name" resettable />
//     <TextInput label="Code" source="code" resettable />
//     <ReferenceInput label="Parent" source="parent" reference="tags">
//       <SelectInput optionText="name" />
//     </ReferenceInput>
//   </Filter>
// );

export const CustomFilterList = (props) => {
  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      perPage={20}
      pagination={<CustomFilterPagination />}
      // filters={<CustomFilterFilter />}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="code" />
        <MyReferenceField
          source="parent"
          label="Parent"
          reference="tags"
          sortable={false}
        >
          <TextField source="name" />
        </MyReferenceField>
      </Datagrid>
    </List>
  );
};
