import { stringify } from "query-string";
import { apiUrl, adminApiUrl } from "./config";
import { httpClient } from "./util";

//temporary function to get proper apiUrl
const getProperApiUrl = (resource) => {
  let properApiUrl = "";

  if (
    resource === "books" ||
    resource === "interests" ||
    resource === "filters" ||
    resource === "customfilters"
  ) {
    properApiUrl = apiUrl;
  } else {
    properApiUrl = adminApiUrl;
  }

  return properApiUrl;
};

const switchResourceAndFormat = (resource, params) => {
  let data = {};

  switch (resource) {
    case "books":
      data.book = {
        title: params.data.title,
        slug: params.data.slug,
        author: params.data.author,
        description_short: params.data.description_short,
        description_long: params.data.description_long,
        is_live: params.data.is_live,
        age_level: params.data.age_level,
        illustrators: params.data.illustrators,
        copyright_year: params.data.copyright_year,
        video_status: params.data.video_status || "waiting_for_upload",
        is_public: params.data.is_public || false,
      };
      break;
    case "activities":
      data.activity = {
        name: params.data.name,
        description: params.data.description,
        book_id: params.data.book_id,
        resource_uri: params.data.resource_uri,
      };
      break;
    case "channels":
      data.channel = {
        title: params.data.title,
        description: params.data.description,
        books_ids: params.data.books_ids,
        visibility: params.data.visibility,
        is_carousel: params.data.is_carousel,
      };
      break;
    case "users":
      data = {
        first_name: params.data.first_name,
        last_name: params.data.last_name,
        email: params.data.email,
        password: params.data.password,
        password_confirmation: params.data.password_confirmation,
        ...(params.data.country_code ? {country_code: params.data.country_code} : {}),
        ...(params.data.source ? {source: params.data.source} : {}),
        preferred_language: params.data.preferred_language,
      };
      break;
    case "tags":
      data.tag = {
        name: params.data.name,
        code: params.data.code,
        is_interest: params.data.is_interest,
        is_filter: params.data.is_filter,
        is_custom: params.data.is_custom,
        is_visible: params.data.is_visible,
        parent: params.data.parent,
      };
      break;
    case "popularsearch":
      data.popularsearch = {
        visibility: params.data.visibility,
      };
      break;
    case "avatars":
      data.avatar = {
        name: params.data.name,
        avatar_collection_id: params.data.avatar_collection_id,
        visibility: params.data.visibility,
      };
      break;
    case "avatar-collections":
      data.avatarCollection = {
        name: params.data.name,
        visibility: params.data.visibility,
      };
      break;
    default:
      //do nothing
      break;
  }

  return data;
};

const getKey = (resource) => {
  let key = resource;

  if (resource === "popularsearch") {
    key = "popularSearches";
  }

  if (resource === "avatar-collections") {
    key = "avatarCollections";
  }

  if (
    resource === "interests" ||
    resource === "filters" ||
    resource === "customfilters"
  ) {
    key = "tags";
  }

  return key;
};

export default {
  getList: (resource, params) => {
    const {
      pagination: { page, perPage },
      filter: { q, ...rest },
      sort: { field: sort, order },
    } = params;

    const getSortParam = () => {
      if (resource === "channels") {
        return sort && sort !== "id" ? sort : "order";
      }

      return sort;
    };

    const query = {
      page: page - 1,
      pageSize: perPage,
      q: q ? q.trim() : q,
      order,
      sort: getSortParam(),
      filters: Object.keys(rest).length > 0 ? JSON.stringify(rest) : null,
    };

    const url = `${getProperApiUrl(resource)}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ json }) => ({
      data: json[getKey(resource)],
      total: json.pageData.total,
    }));
  },

  getOne: (resource, params) => {
    if (
      resource === "interests" ||
      resource === "filters" ||
      resource === "customfilters"
    ) {
      resource = "tags";
    }

    return httpClient(
      `${getProperApiUrl(resource)}/${resource}/${params.id}`
    ).then(({ json }) => ({
      data: json,
    }));
  },

  getMany: (resource, params) => {
    const query = {
      pageSize: 10000, //Insane big number so all reference are shown
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${getProperApiUrl(resource)}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({
      data: json[getKey(resource)],
    }));
  },

  update: (resource, params) => {
    if (
      resource === "interests" ||
      resource === "filters" ||
      resource === "customfilters"
    ) {
      resource = "tags";
    }

    const data = switchResourceAndFormat(resource, params);

    return httpClient(`${getProperApiUrl(resource)}/${resource}/${params.id}`, {
      method: "PATCH",
      body: JSON.stringify(data),
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource, params) => {
    if (resource === "interests") {
      resource = "tags";
      params.data.is_interest = true;
    }

    if (resource === "filters") {
      resource = "tags";
      params.data.is_filter = true;
    }

    if (resource === "customfilters") {
      resource = "tags";
      params.data.is_custom = true;
    }

    const data = switchResourceAndFormat(resource, params);

    return httpClient(`${getProperApiUrl(resource)}/${resource}`, {
      method: "POST",
      body: JSON.stringify(data),
    }).then(({ json }) => ({ data: json }));
  },

  delete: (resource, params) => {
    if (resource === "interests") {
      params.data = { ...params.previousData };
      params.data.is_interest = false;

      const data = switchResourceAndFormat("tags", params);

      return httpClient(`${getProperApiUrl("tags")}/${"tags"}/${params.id}`, {
        method: "PATCH",
        body: JSON.stringify(data),
      }).then(({ json }) => ({ data: json }));
    }

    if (resource === "filters") {
      params.data = { ...params.previousData };
      params.data.is_filter = false;

      const data = switchResourceAndFormat("tags", params);

      return httpClient(`${getProperApiUrl("tags")}/${"tags"}/${params.id}`, {
        method: "PATCH",
        body: JSON.stringify(data),
      }).then(({ json }) => ({ data: json }));
    }

    if (resource === "customfilters") {
      params.data = { ...params.previousData };
      params.data.is_custom = false;

      const data = switchResourceAndFormat("tags", params);

      return httpClient(`${getProperApiUrl("tags")}/${"tags"}/${params.id}`, {
        method: "PATCH",
        body: JSON.stringify(data),
      }).then(({ json }) => ({ data: json }));
    }

    return httpClient(`${getProperApiUrl(resource)}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then((res) => ({ data: res }));
  },

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };

    return httpClient(
      `${getProperApiUrl(resource)}/${resource}?${stringify(query)}`,
      {
        method: "DELETE",
        body: JSON.stringify(params.data),
      }
    ).then(({ json }) => ({ data: json }));
  },
};
