import React from "react";
import {
    Edit,
    SelectInput,
    TextInput,
    Toolbar,
    SaveButton,
    DeleteWithConfirmButton,
    TabbedForm,
    FormTab,
    DateInput,
    BooleanField, TextField, SelectField, DateField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import {
  validateEmail,
  validatePasswordMatch,
  validatePassword,
} from "../../util";
import {getDecoratedCountryList, getDecoratedLanguageList} from "../../utils/countryUtils";
import {SUBSCRIPTION_STORES, USER_ROLES, USER_SOURCES} from "../../constants";

const useStyles = makeStyles({
  toolbar: { display: "flex", justifyContent: "space-between" },
});

const CustomToolbar = (props) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
    <DeleteWithConfirmButton
      confirmTitle={`Delete user "${props.record.first_name} ${props.record.last_name}"`}
      confirmContent="Are you sure you want to delete this User? This action is permanent."
    />
  </Toolbar>
);

const UserTitle = ({ record }) => (
  <span>
    User {record ? `${record.first_name || ''} ${record.last_name || ''}` : "edit"}
  </span>
);


export const UserEdit = (props) => (
    <Edit title={<UserTitle />} undoable={false} {...props}>
        <TabbedForm
            redirect="edit"
            toolbar={<CustomToolbar />}
            warnWhenUnsavedChanges
        >
            <FormTab label="Basic">
                <TextInput source="first_name" fullWidth />
                <TextInput source="last_name" fullWidth />
                <TextInput source="email" fullWidth validate={validateEmail} />
                <SelectInput
                    source="country_code"
                    label="Country"
                    emptyValue={null}
                    choices={getDecoratedCountryList()}
                    fullWidth
                />
                <SelectInput
                    source="preferred_language"
                    emptyValue={null}
                    choices={getDecoratedLanguageList()}
                    fullWidth
                    allowEmpty
                    emptyText="None"
                />
                <DateInput source="created_at" disabled fullWidth />
                <DateInput source="updated_at" disabled fullWidth />
                <TextInput source="password" fullWidth validate={validatePassword} />
                <TextInput
                    source="password_confirmation"
                    fullWidth
                    validate={validatePasswordMatch}
                />
            </FormTab>
            <FormTab label="Advanced">
                <SelectInput source="source" choices={USER_SOURCES} fullWidth />
                <SelectField source="role" choices={USER_ROLES} fullWidth />
                <TextField source="id" disabled fullWidth />
                <TextField source="uuid" emptyText="N/D" label="UUID" disabled fullWidth />
                <TextField source="external_id" emptyText="N/D" label="External ID" disabled fullWidth />
                <BooleanField source="is_migrated" fullWidth />
            </FormTab>
            <FormTab label="Subscription">
                <TextField source="customer.subscription.id" emptyText="N/D" label="Subscription ID" fullWidth />
                <SelectField source="customer.subscription.store" emptyText="N/D" label="Store" choices={SUBSCRIPTION_STORES} fullWidth />
                <TextField source="customer.stripe_id" emptyText="N/D" label="Stripe customer ID" fullWidth />
                <TextField source="customer.subscription.stripe_id" emptyText="N/D" label="Stripe subscription ID" fullWidth />
                <TextField source="customer.subscription.interval" emptyText="N/D" label="Periodicity" fullWidth />
                <TextField source="customer.subscription.trial_remaining_days" emptyText="N/D" label="Trial remaining days" fullWidth />
                <DateField source="customer.subscription.current_period_end" emptyText="N/D" label="Current period end" fullWidth />
                <BooleanField source="customer.subscription.cancel_at_period_end" emptyText="N/D" label="Cancel at period end" fullWidth />
                <BooleanField source="customer.subscription.is_paused" emptyText="N/D" label="Paused" fullWidth />
                <BooleanField source="customer.subscription.canceled" emptyText="N/D" label="Canceled" fullWidth />
            </FormTab>
        </TabbedForm>
    </Edit>
);
