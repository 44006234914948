import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  BooleanInput,
} from "react-admin";
import { validateTitle } from "../../util";

export const ChannelCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list" warnWhenUnsavedChanges>
      <TextInput source="title" fullWidth validate={validateTitle} />
      <TextInput source="description" fullWidth />
      <BooleanInput label="Visible" source="visibility" fullWidth />
      <BooleanInput
        label="Set as homepage carousel"
        source="is_carousel"
        fullWidth
      />
      <ReferenceArrayInput
        label="Books"
        reference="books"
        source="books_ids"
        perPage={10000}
        fullWidth
      >
        <AutocompleteArrayInput optionText="title" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);
