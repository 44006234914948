import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  Pagination,
  Filter,
  TextInput,
} from "react-admin";

const ActivitiesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 20, 50, 100]} {...props} />
);

const MyReferenceField = (props) => {
  return (
    <ReferenceField {...props}>
      <TextField source="id" />
    </ReferenceField>
  );
};

const ActivityFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn resettable />
    <TextInput label="Name" source="name" resettable />
    <TextInput label="Description" source="description" resettable />
  </Filter>
);

export const ActivitiesList = (props) => {
  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      perPage={20}
      pagination={<ActivitiesPagination />}
      filters={<ActivityFilter />}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="resource_uri" sortable={false} />
        <MyReferenceField
          source="book_id"
          label="Book"
          reference="books"
          sortable={false}
        >
          <TextField source="book_id" />
        </MyReferenceField>
      </Datagrid>
    </List>
  );
};
