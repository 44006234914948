import React from "react";
import { Admin, Resource } from "react-admin";
import BookIcon from "@material-ui/icons/Book";
import ExtensionIcon from "@material-ui/icons/Extension";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import UsersIcon from "@material-ui/icons/Group";
import PopularSearchesIcon from "@material-ui/icons/Search";
import TagsIcon from "@material-ui/icons/LocalOffer";
import AvatarsIcon from "@material-ui/icons/Face";
import AvatarCollectionsIcon from "@material-ui/icons/SupervisedUserCircle";
import InterestIcon from "@material-ui/icons/Loyalty";
import FiltersIcon from "@material-ui/icons/FilterList";
import CustomFiltersIcon from "@material-ui/icons/FilterNone";
import authProvider from "./authProvider";
import dataProvider from "./dataProvider";
import { BookList } from "./components/Book/BookList";
import { BookEdit } from "./components/Book/BookEdit";
import { BookCreate } from "./components/Book/BookCreate";
import { ChannelList } from "./components/Channel/ChannelList";
import { ChannelEdit } from "./components/Channel/ChannelEdit";
import { ChannelCreate } from "./components/Channel/ChannelCreate";
import { UserList } from "./components/User/UserList";
import { UserEdit } from "./components/User/UserEdit";
import { TagsList } from "./components/Tag/TagList";
import { TagCreate } from "./components/Tag/TagCreate";
import { TagEdit } from "./components/Tag/TagEdit";
import { PopularSearchList } from "./components/PopularSearch/PopularSearchList";
import { AvatarList } from "./components/Avatar/AvatarList";
import { AvatarCollectionList } from "./components/AvatarCollection/AvatarCollectionList";
import { AvatarEdit } from "./components/Avatar/AvatarEdit";
import { AvatarCollectionEdit } from "./components/AvatarCollection/AvatarCollectionEdit";
import { AvatarCreate } from "./components/Avatar/AvatarCreate";
import { AvatarCollectionCreate } from "./components/AvatarCollection/AvatarCollectionCreate";
import { InterestList } from "./components/Interest/InterestList";
import { InterestCreate } from "./components/Interest/InterestCreate";
import { InterestEdit } from "./components/Interest/InterestEdit";
import { FilterList } from "./components/Filter/FilterList";
import { FilterEdit } from "./components/Filter/FilterEdit";
import { FilterCreate } from "./components/Filter/FilterCreate";
import { CustomFilterList } from "./components/CustomFilter/CustomFilterList";
import { CustomFilterCreate } from "./components/CustomFilter/CustomFilterCreate";
import { CustomFilterEdit } from "./components/CustomFilter/CustomFilterEdit";
import { ActivitiesList } from "./components/Activity/ActivityList";
import { ActivityCreate } from "./components/Activity/ActivityCreate";
import { ActivityEdit } from "./components/Activity/ActivityEdit";

function App() {
  return (
    <Admin
      title="Vooks Admin"
      authProvider={authProvider}
      dataProvider={dataProvider}
    >
      <Resource
        name="activities"
        list={ActivitiesList}
        edit={ActivityEdit}
        create={ActivityCreate}
        icon={ExtensionIcon}
      />
      <Resource
        name="books"
        list={BookList}
        edit={BookEdit}
        create={BookCreate}
        icon={BookIcon}
      />
      <Resource
        name="channels"
        list={ChannelList}
        edit={ChannelEdit}
        create={ChannelCreate}
        icon={LibraryBooksIcon}
        options={{ label: 'Collections' }} 
      />
      <Resource name="users" list={UserList} edit={UserEdit} icon={UsersIcon} />
      <Resource
        name="popularsearch"
        options={{ label: "Popular Searches" }}
        list={PopularSearchList}
        icon={PopularSearchesIcon}
      />
      <Resource
        name="tags"
        list={TagsList}
        create={TagCreate}
        edit={TagEdit}
        icon={TagsIcon}
      />
      <Resource
        name="avatars"
        list={AvatarList}
        edit={AvatarEdit}
        create={AvatarCreate}
        icon={AvatarsIcon}
      />
      <Resource
        name="avatar-collections"
        options={{ label: "Avatar Collections" }}
        list={AvatarCollectionList}
        edit={AvatarCollectionEdit}
        create={AvatarCollectionCreate}
        icon={AvatarCollectionsIcon}
      />
      <Resource
        name="interests"
        list={InterestList}
        create={InterestCreate}
        edit={InterestEdit}
        icon={InterestIcon}
      />
      <Resource
        name="filters"
        list={FilterList}
        create={FilterCreate}
        edit={FilterEdit}
        icon={FiltersIcon}
      />
      <Resource
        options={{ label: "Custom Filters" }}
        name="customfilters"
        list={CustomFilterList}
        create={CustomFilterCreate}
        edit={CustomFilterEdit}
        icon={CustomFiltersIcon}
      />
    </Admin>
  );
}

export default App;
