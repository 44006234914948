import React, { useState, useEffect } from "react";
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ReferenceArrayField,
  Datagrid,
  TextField,
  useRefresh,
  BooleanInput,
  Toolbar,
  SaveButton,
  DeleteWithConfirmButton,
  FileInput,
  Confirm,
} from "react-admin";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import LinearProgress from "@material-ui/core/LinearProgress";
import { red } from "@material-ui/core/colors";
import { validateTitle } from "../../util";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "../../util";
import { makeStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import ReorderIcon from "@material-ui/icons/Reorder";
import {
  reorderBooks,
  getImageByScope,
  uploadImage,
  deleteImageByScope,
} from "../../services/channel";

const MyDatagridRow = ({
  record,
  resource,
  id,
  children,
  basePath,
  provided,
  snapshot,
}) => {
  return (
    <TableRow
      key={id}
      ref={provided.innerRef}
      {...provided.draggableProps}
      style={{
        ...provided.draggableProps.style,
        background: snapshot.isDragging ? "rgba(245,245,245, 0.75)" : "none",
      }}
    >
      {React.Children.map(children, (field) => (
        <TableCell
          key={`${id}-${field.props.source}`}
          style={{ width: "100%" }}
        >
          {React.cloneElement(field, {
            record,
            basePath,
            resource,
          })}
        </TableCell>
      ))}
      <TableCell
        style={{
          borderTop: !snapshot.isDragging && "1px solid rgb(224, 224, 224)",
        }}
      >
        <div {...provided.dragHandleProps}>
          <ReorderIcon />
        </div>
      </TableCell>
    </TableRow>
  );
};

const MyDatagridBody = (props) => {
  const refresh = useRefresh();
  const [books, setBooks] = useState([]);

  useEffect(() => {
    const tempBooks = props.ids.map((id) => props.data[id]);
    setBooks(tempBooks);
  }, [props.data, props.ids]);

  const handleDragEnd = ({ source, destination }) => {
    if (!source || !destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    const tempBooks = reorder(books, source.index, destination.index);
    setBooks(tempBooks);

    const bookIds = tempBooks.map((b) => b.id);
    reorderBooks(props.channel, bookIds).then(refresh);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable" direction="vertical">
        {(provided) => (
          <TableBody ref={provided.innerRef} {...provided.droppableProps}>
            {books.map((book = {}, index) => (
              <Draggable
                key={index}
                draggableId={index.toString()}
                index={index}
              >
                {(provided, snapshot) => {
                  return (
                    <MyDatagridRow
                      record={book}
                      {...props}
                      id={book.id}
                      provided={provided}
                      snapshot={snapshot}
                    />
                  );
                }}
              </Draggable>
            ))}
            {provided.placeholder}
          </TableBody>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const MyDatagrid = (props) => (
  <Datagrid {...props} body={<MyDatagridBody channel={props.channel} />} />
);

const ChannelTitle = ({ record }) => (
  <span>Collection {record ? `"${record.title}"` : "edit"}</span>
);

const useStyles = makeStyles({
  toolbar: { display: "flex", justifyContent: "space-between" },
});

const CustomToolbar = (props) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
    <DeleteWithConfirmButton
      confirmTitle={`Delete collection "${props.record.title}"`}
      confirmContent="Are you sure you want to delete this Collection? This action is permanent."
    />
  </Toolbar>
);

const ImageUploader = ({ record }) => {
  const refresh = useRefresh();
  const [uploading, setUploading] = useState(false);
  const [imageScope, setImageScope] = useState("portrait");
  const [image, setImage] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const getImage = () => {
      setImage(null);

      getImageByScope({
        bookId: record.id,
        imageScope,
      })
        .then((res) => {
          setImage(res.data);
        })
        .catch(() => {
          setImage(null);
        });
    };

    if (imageScope) {
      getImage();
    }
  }, [imageScope, record.id]);

  const uploadThisImage = (file) => {
    if (file) {
      setUploading(true);
      uploadImage({ channelId: record.id, imageScope, imageFile: file }).then(
        () => {
          getImageByScope({
            channelId: record.id,
            imageScope,
          })
            .then((res) => {
              setImage(res.data);
            })
            .catch(() => {
              setImage(null);
            })
            .finally(refresh);
        }
      );
    }
  };

  const deleteThisImage = () => {
    deleteImageByScope({
      channelId: record.id,
      imageScope,
    }).then(refresh);
  };

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  return (
    <>
      <FormControl component="fieldset">
        <FormLabel
          focused={false}
          style={{
            transform: "translate(0, 1.5px) scale(0.75)",
            transformOrigin: "top left",
          }}
        >
          Image type
        </FormLabel>

        <RadioGroup
          aria-label="imageType"
          name="imageType"
          value={imageScope}
          onChange={(e) => setImageScope(e.target.value)}
          row
        >
          <FormControlLabel
            value="portrait"
            control={<Radio color="primary" />}
            label="Portrait Image"
            disabled={uploading}
          />
          <FormControlLabel
            value="landscape"
            control={<Radio color="primary" />}
            label="Landscape Image"
            disabled={uploading}
          />
        </RadioGroup>
      </FormControl>
      {!uploading && (
        <>
          {!image && (
            <FileInput
              source="image"
              label="Image"
              accept="image/*"
              onChange={uploadThisImage}
            >
              <></>
            </FileInput>
          )}
          {image && (
            <>
              <img className="image-preview" src={image.imageUrl} alt="" />
              <Button
                className="btn-delete"
                variant="contained"
                color="primary"
                startIcon={<DeleteIcon />}
                size="small"
                style={{
                  backgroundColor: red[500],
                  width: 165,
                }}
                onClick={handleClick}
              >
                DELETE IMAGE
              </Button>
            </>
          )}
        </>
      )}
      {uploading && (
        <>
          <LinearProgress />
          <span style={{ display: "block", marginTop: 8 }}>
            Uploading Image..
          </span>
        </>
      )}
      <Confirm
        isOpen={open}
        title="Delete image"
        content="Are you sure you want to delete this image?"
        onConfirm={deleteThisImage}
        onClose={handleDialogClose}
      />
    </>
  );
};

export const ChannelEdit = (props) => {
  const refresh = useRefresh();

  return (
    <Edit title={<ChannelTitle />} undoable={false} {...props}>
      <TabbedForm
        redirect="edit"
        toolbar={<CustomToolbar />}
        warnWhenUnsavedChanges
      >
        <FormTab label="Collection">
          <TextInput source="title" fullWidth validate={validateTitle} />
          <TextInput source="description" fullWidth />
          <BooleanInput label="Visible" source="visibility" fullWidth />
          <BooleanInput
            label="Set as homepage carousel"
            source="is_carousel"
            fullWidth
          />
        </FormTab>
        <FormTab label="Books">
          <ReferenceArrayInput
            label="Books"
            reference="books"
            source="books_ids"
            perPage={10000}
            fullWidth
          >
            <AutocompleteArrayInput optionText="title" />
          </ReferenceArrayInput>
        </FormTab>
        <FormTab label="Images" onClick={refresh}>
          <ImageUploader {...props} />
        </FormTab>
        <FormTab label="Books Order" onClick={refresh}>
          <ReferenceArrayField
            label="Books order"
            reference="books"
            source="books_ids"
            perPage={10000}
            fullWidth
          >
            <MyDatagrid channel={props.id}>
              <TextField source="title" label="" sortable={false} />
            </MyDatagrid>
          </ReferenceArrayField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
