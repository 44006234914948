import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Pagination,
  ImageField,
  ReferenceField,
  Filter,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanField,
} from "react-admin";

const AvatarPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 20, 50, 100]} {...props} />
);

const AvatarFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn resettable />
    <TextInput label="Name" source="name" resettable />
    <ReferenceInput
      label="Collection"
      source="avatar_collection_id"
      reference="avatar-collections"
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const AvatarList = (props) => (
  <List
    {...props}
    exporter={false}
    perPage={20}
    bulkActionButtons={false}
    pagination={<AvatarPagination />}
    filters={<AvatarFilter />}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <ImageField
        source="image_original_img"
        label="Image original"
        className="avatar-image"
        sortable={false}
      />
      <BooleanField source="visibility" sortable={false} />
      <ReferenceField
        source="avatar_collection_id"
        reference="avatar-collections"
        sortable={false}
      >
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);
