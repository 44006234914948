import React from "react";
import {
  Edit,
  DeleteWithConfirmButton,
  SimpleForm,
  TextInput,
  BooleanInput,
  Button,
  Link,
  ReferenceArrayField,
  SaveButton,
  SingleFieldList,
  Toolbar,
} from "react-admin";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  toolbar: { display: "flex", justifyContent: "space-between" },
});

const CustomToolbar = (props) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
    <DeleteWithConfirmButton
      confirmTitle={`Delete collection "${props.record.name}"`}
      confirmContent="Are you sure you want to delete this collection? This action is permanent."
    />
  </Toolbar>
);

const AddNewAvatar = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: "/avatars/create",
      state: { record: { avatar_collection_id: record.id } },
    }}
    label="Add new Avatar"
  />
);

const ReferenceFieldComp = ({ record }) => {
  if (record) {
    return (
      <span style={{ margin: 4, display: "inline-flex" }}>
        {record.image_original_img ? (
          <img
            className="avatar-collection-img"
            src={record.image_original_img}
            alt={record.name}
          />
        ) : (
          <Chip label={record.name} style={{ cursor: "pointer" }} />
        )}
      </span>
    );
  }

  return null;
};

export const AvatarCollectionEdit = (props) => (
  <Edit undoable={false} title="Edit Avatar Collection" {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput source="name" />
      <BooleanInput source="visibility" />
      <ReferenceArrayField
        label="Avatars"
        reference="avatars"
        source="avatars"
        perPage={10000}
        fullWidth
      >
        <SingleFieldList>
          <ReferenceFieldComp />
        </SingleFieldList>
      </ReferenceArrayField>
      <AddNewAvatar />
    </SimpleForm>
  </Edit>
);
