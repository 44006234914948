import React, { useState, useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  BooleanField,
  Pagination,
  Filter,
  TextInput,
  BooleanInput,
} from "react-admin";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorderChannels, getAllChannels } from "../../services/channel";
import { reorder } from "../../util";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import ReorderIcon from "@material-ui/icons/Reorder";

const MyDatagridRow = ({
  record,
  resource,
  id,
  children,
  basePath,
  provided,
  snapshot,
}) => {
  const calculateCellStyle = (source) => {
    // If we sometime add more columns to the table we will need to recalculate this widths.
    // We are using fixed widths in the table cells so when dragging we don't loose the table layout
    switch (source) {
      case "title":
        return { width: "15%" };
      case "description":
        return { width: "50%" };
      case "visibility":
        return { width: "10%" };
      case "is_carousel":
        return { width: "10%" };
      default:
        return { width: "5%" };
    }
  };

  return (
    <TableRow
      key={id}
      ref={provided.innerRef}
      {...provided.draggableProps}
      style={{
        ...provided.draggableProps.style,
        background: snapshot.isDragging ? "rgba(245,245,245, 0.75)" : "none",
      }}
    >
      {/* data columns based on children */}
      {React.Children.map(children, (field) => (
        <TableCell
          key={`${id}-${field.props.source}`}
          style={calculateCellStyle(field.props.source)}
        >
          {React.cloneElement(field, {
            record,
            basePath,
            resource,
          })}
        </TableCell>
      ))}
      <TableCell
        style={{
          borderTop: !snapshot.isDragging && "1px solid rgb(224, 224, 224)",
        }}
      >
        <div {...provided.dragHandleProps}>
          <ReorderIcon />
        </div>
      </TableCell>
    </TableRow>
  );
};

const MyDatagridBody = (props) => {
  const [channels, setChannels] = useState([]);

  useEffect(() => {
    const tempChannels = props.ids.map((id) => props.data[id]);
    setChannels(tempChannels);
  }, [props.data, props.ids]);

  const handleDragEnd = async ({ source, destination }) => {
    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    const { data } = await getAllChannels();

    const tempChannels = reorder(
      data.channels,
      source.index,
      destination.index
    );
    setChannels(tempChannels);

    const channelIds = tempChannels.map((c) => c.id);
    reorderChannels({ channelIds });
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable" direction="vertical">
        {(provided) => (
          <TableBody ref={provided.innerRef} {...provided.droppableProps}>
            {channels.map((channel, index) => (
              <Draggable
                key={channel.id}
                draggableId={channel.id.toString()}
                index={index}
              >
                {(provided, snapshot) => {
                  return (
                    <MyDatagridRow
                      record={channel}
                      {...props}
                      id={channel.id}
                      provided={provided}
                      snapshot={snapshot}
                    />
                  );
                }}
              </Draggable>
            ))}
            {provided.placeholder}
          </TableBody>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const MyDatagrid = (props) => <Datagrid {...props} body={<MyDatagridBody />} />;

const ChannelsPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 20, 50, 100]} {...props} />
);

const ChannelFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn resettable />
    <TextInput label="Title" source="title" resettable />
    <TextInput label="Description" source="description" resettable />
    <BooleanInput label="Visible" source="visibility" />
    <BooleanInput label="Is Carousel" source="is_carousel" />
  </Filter>
);

export const ChannelList = (props) => (
  <List
    {...props}
    exporter={false}
    bulkActionButtons={false}
    perPage={20}
    pagination={<ChannelsPagination />}
    filters={<ChannelFilter />}
  >
    <MyDatagrid>
      <TextField source="title" label="Title" />
      <TextField source="description" label="Description" />
      <BooleanField source="visibility" sortable={false} />
      <BooleanField
        source="is_carousel"
        label="Is homepage carousel"
        sortable={false}
      />
      <EditButton />
    </MyDatagrid>
  </List>
);
